// @flow

// status icons.
import Stopped from 'assets/status/stopped.svg';
import TempAlert from 'assets/status/temp-alert.svg';
import TheftEvent from 'assets/status/theft-alert-event.svg'
import ThieftEventTransition from 'assets/status/theft-alert-white-new-after.svg'
// import Accelerometer from 'assets/status/orange-odometer.svg';

// aux icons from status
import AuxBottom0 from 'assets/status/aux-bottom-0.svg';
import AuxBottom1 from 'assets/status/aux-bottom-1.svg';
import AuxBottom2 from 'assets/status/aux-bottom-2.svg';
import AuxBottom3 from 'assets/status/aux-bottom-3.svg';
import AuxBottom4 from 'assets/status/aux-bottom-4.svg';

import AuxBottom12 from 'assets/status/aux-bottom-12.svg';
import AuxBottom13 from 'assets/status/aux-bottom-13.svg';
import AuxBottom14 from 'assets/status/aux-bottom-14.svg';

import AuxBottom23 from 'assets/status/aux-bottom-23.svg';
import AuxBottom24 from 'assets/status/aux-bottom-24.svg';
import AuxBottom34 from 'assets/status/aux-bottom-34.svg';

import AuxBottom123 from 'assets/status/aux-bottom-123.svg';
import AuxBottom124 from 'assets/status/aux-bottom-124.svg';
import AuxBottom134 from 'assets/status/aux-bottom-134.svg';
import AuxBottom234 from 'assets/status/aux-bottom-234.svg';

import AuxBottom1234 from 'assets/status/aux-bottom-1234.svg';

import MinSpeed from 'assets/status/min-speed.svg';
import MaxSpeed from 'assets/status/max-speed.svg';
import Impact from 'assets/status/impact-alert.svg';
import ProlongedIdling from 'assets/status/prolonged-idle.svg';
import EngineCut from 'assets/status/engine-cut.svg';
// import Multiple from 'assets/status/multiple_vehicles.svg';
import OutOfNetwork from 'assets/status/outside-network.svg';
import ConnectCell from 'assets/status/cell-connect.svg';
import Weight from 'assets/status/weight.svg';
import LowPower from 'assets/status/low-power.svg';

// white status icons.
import TempAlertWhite from 'assets/status/temp-alert-white.svg';
import FastAccWhite from 'assets/status/fast-acc-white.svg';
import FastDecelWhite from 'assets/status/fast-dec-white.svg';

// New White
import StatusTempAlertWhite from 'assets/statuses/temp-alert-white.svg';
import StatusTempAlertWhiteHighlight from 'assets/statuses/temp-alert-white-highlight.svg';
import StatusFastAccWhite from 'assets/statuses/fast-acc-white.svg';
import StatusFastDecelWhite from 'assets/statuses/fast-dec-white.svg';
import star from 'assets/statuses/starHighlight.svg';

// aux vehicle icons
import Aux0 from 'assets/statuses/aux-0.svg';

import Aux1 from 'assets/statuses/aux-1.svg';
import Aux2 from 'assets/statuses/aux-2.svg';
import Aux3 from 'assets/statuses/aux-3.svg';
import Aux4 from 'assets/statuses/aux-4.svg';

import Aux12 from 'assets/statuses/aux-12.svg';
import Aux13 from 'assets/statuses/aux-13.svg';
import Aux14 from 'assets/statuses/aux-14.svg';

import Aux23 from 'assets/statuses/aux-23.svg';
import Aux24 from 'assets/statuses/aux-24.svg';
import Aux34 from 'assets/statuses/aux-34.svg';

import Aux123 from 'assets/statuses/aux-123.svg';
import Aux124 from 'assets/statuses/aux-124.svg';
import Aux134 from 'assets/statuses/aux-134.svg';
import Aux234 from 'assets/statuses/aux-234.svg';

import Aux1234 from 'assets/status/aux-bottom-1234.svg';

import Aux0Off from 'assets/statuses/aux-0-off.svg';

import Aux1Off from 'assets/statuses/aux-1-white.svg';
import Aux2Off from 'assets/statuses/aux-2-white.svg';
import Aux3Off from 'assets/statuses/aux-3-white.svg';
import Aux4Off from 'assets/statuses/aux-4-white.svg';

import Aux12Off from 'assets/statuses/aux-12-off.svg';
import Aux13Off from 'assets/statuses/aux-13-off.svg';
import Aux14Off from 'assets/statuses/aux-14-off.svg';

import Aux23Off from 'assets/statuses/aux-23-off.svg';
import Aux24Off from 'assets/statuses/aux-24-off.svg';
import Aux34Off from 'assets/statuses/aux-34-off.svg';

import Aux123Off from 'assets/statuses/aux-123-off.svg';
import Aux124Off from 'assets/statuses/aux-124-off.svg';
import Aux134Off from 'assets/statuses/aux-134-off.svg';
import Aux234Off from 'assets/statuses/aux-234-off.svg';

import Aux1234Off from 'assets/statuses/aux-1234-off.svg';

// custom icons
import backHoeIcon from 'assets/custom/vehicleIcons/back-hoe.svg';
import conveyorIcon from 'assets/custom/vehicleIcons/conveyor.svg';
import forkliftIcon from 'assets/custom/vehicleIcons/forklift.svg';
import generatorIcon from 'assets/custom/vehicleIcons/generator.svg';
import groveIcon from 'assets/custom/vehicleIcons/grove.svg';
import loaderIcon from 'assets/custom/vehicleIcons/loader.svg';
import minivanIcon from 'assets/custom/vehicleIcons/minivan.svg';
import paverIcon from 'assets/custom/vehicleIcons/paver.svg';
import recycleIcon from 'assets/custom/vehicleIcons/recycle.svg';
import rollerIcon from 'assets/custom/vehicleIcons/roller.svg';
import semiTrailerIcon from 'assets/custom/vehicleIcons/semi-trailer.svg';
import snowPlowIcon from 'assets/custom/vehicleIcons/snow-plow.svg';
import truckTractorIcon from 'assets/custom/vehicleIcons/truck-tractor.svg';
import truckTrashIcon from 'assets/custom/vehicleIcons/truck-trash.svg';
import truckIcon from 'assets/custom/vehicleIcons/truck.svg';
import vehicleIcon from 'assets/custom/vehicleIcons/vehicle.svg';
import vttIcon from 'assets/custom/vehicleIcons/VTT.svg';
import lightingTower from 'assets/custom/vehicleIcons/lighting-tower.svg';
import lightingPlatform from 'assets/custom/vehicleIcons/plate-forme-elevatrice.svg';
import excavator from 'assets/custom/vehicleIcons/excavatrice.svg';
import boat from 'assets/custom/vehicleIcons/chaloupe.svg';
import compressor from 'assets/custom/vehicleIcons/compresseur.svg';
import welder from 'assets/custom/vehicleIcons/soudeuse.svg';
import drill from 'assets/custom/vehicleIcons/foreuse.svg';
import ripperTeeth from 'assets/custom/vehicleIcons/dents-defonceuse.svg';
import forklifts from 'assets/custom/vehicleIcons/chariot-elevateur.svg';
import toothedBucket from 'assets/custom/vehicleIcons/godet-a-dents.svg';
import hydraulicJack from 'assets/custom/vehicleIcons/jack-hydraulique.svg';
//import defaultVeh from 'assets/custom/vehicleIcons/default-veh.svg';
import defaultVeh from 'assets/statuses/vehicleIcon-default.svg';
import defaultVehCustom from 'assets/custom/vehicleIcons/default-veh.svg';

import accelerationIcon from 'assets/custom/auxIcons/acceleration.svg';
import alarmeIcon from 'assets/custom/auxIcons/alarme.svg';
import alarmeEndIcon from 'assets/custom/auxIcons/alarmeEnd.svg';
import backDoorIcon from 'assets/custom/auxIcons/backDoor.svg';
import benneIcon from 'assets/custom/auxIcons/benne.svg';
import buttonIcon from 'assets/custom/auxIcons/button.svg';
import decelerationIcon from 'assets/custom/auxIcons/deceleration.svg';
import driverDoorIcon from 'assets/custom/auxIcons/driverDoor.svg';
import exceedIcon from 'assets/custom/auxIcons/exceed.svg';
import exceedEndIcon from 'assets/custom/auxIcons/exceedEnd.svg';
import fourDoorsIcon from 'assets/custom/auxIcons/fourDoors.svg';
import frontPlowIcon from 'assets/custom/auxIcons/frontPlow.svg';
import garbageBinIcon from 'assets/custom/auxIcons/garbageBin.svg';
import identificationIcon from 'assets/custom/auxIcons/identification.svg';
import keyStartIcon from 'assets/custom/auxIcons/keyStart.svg';
import nonAutoriseIcon from 'assets/custom/auxIcons/nonAutorise.svg';
import passengerDoor from 'assets/custom/auxIcons/passengerDoor.svg';
import personIcon from 'assets/custom/auxIcons/person.svg';
import ptoIcon from 'assets/custom/auxIcons/PTO.svg';
import reverseIcon from 'assets/custom/auxIcons/reverse.svg';
import seatbeltsIcon from 'assets/custom/auxIcons/seatbelts.svg';
import sidePlowIcon from 'assets/custom/auxIcons/sidePlow.svg';
import beaconIcon from 'assets/custom/auxIcons/beacon.svg';
import smokeDetectorIcon from 'assets/custom/auxIcons/smokeDetector.svg';
import startButtonIcon from 'assets/custom/auxIcons/startButton.svg';
import telephoneIcon from 'assets/custom/auxIcons/telephone.svg';
import temperatureIcon from 'assets/custom/auxIcons/temperature.svg';
import personalButtonIcon from 'assets/custom/auxIcons/personalButton.svg';
import professionalButtonIcon from 'assets/custom/auxIcons/professionalButton.svg';

import colors from 'styles/colors';

import type { Coordinate } from 'types';

import { List } from 'immutable';
import React from "react";
import {FormattedMessage} from "react-intl";

export const renderCustomizedLabelPie = ({
                                   cx,
                                   cy,
                                   midAngle,
                                   innerRadius,
                                   outerRadius,
                                   percent,
                                   index,
                                    value,
                               }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 1.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        value !== 0?
        <text
            style={{ fontSize: '15px', color: colors.black }}
            x={ value !== 0 ? x : 0}
            y={ value !== 0 ? y : 0}
            fill="black"
            stroke='none'
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
            : null
    );
};

export const renderLegend = (data,i18nDistanceSymbol,type,excelKey) => {
    return (
        <div style={{ borderColor: colors.green61 }}>
            <ul>
                {data.map((entry, index) => (
                    <li
                        key={`item-${index}`}
                        color={entry.fill}
                        style={{ color: entry.fill, fontSize: '15px', textAlign:'left' }}
                    >
                        {entry.name} : {entry.value}{' '}
                        {type && type == 'distance' ? i18nDistanceSymbol : null}
                    </li>
                ))}
                {data && data.length > 1 && excelKey != 'geo_name' ? <li
                    key={`item-total`}
                    color={colors.black}
                    style={{ color: colors.black, fontSize: '15px',textAlign:'left' }}
                >
                    <FormattedMessage id={'containers.Reports.Generators.total.algo'} /> : {data.map((entry, index) => entry.value).reduce((a, b) => Number(a + b))}{' '}
                    {type && type == 'distance' ? i18nDistanceSymbol : null}
                </li> : null}
            </ul>
        </div>
    );
};

export const getAuxStatus = (sensor_mask: ?(number[])) => {
    let description;
    let status = null;
    let color;
    let icon;
    let combination = '';

    if (sensor_mask != null) {
        for (let i = 0; i < sensor_mask.length; i++) {
            combination += sensor_mask[i];
        }
        switch (combination) {
            case '0000': {
                color = colors.status.green;
                icon = AuxBottom0;
                description = 'AUX 0';
                break;
            }
            case '1000': {
                color = colors.status.green;
                icon = AuxBottom1;
                description = 'AUX 1';
                break;
            }
            case '0100': {
                color = colors.status.green;
                icon = AuxBottom2;
                description = 'AUX 2';
                break;
            }
            case '0010': {
                color = colors.status.green;
                icon = AuxBottom3;
                description = 'AUX 3';
                break;
            }
            case '0001': {
                color = colors.status.green;
                icon = AuxBottom4;
                description = 'AUX 4';
                break;
            }
            case '1100': {
                color = colors.status.green;
                icon = AuxBottom12;
                description = 'AUX 1 & 2';
                break;
            }
            case '1010': {
                color = colors.status.green;
                icon = AuxBottom13;
                description = 'AUX 1 & 3';
                break;
            }
            case '1001': {
                color = colors.status.green;
                icon = AuxBottom14;
                description = 'AUX 1 & 4';
                break;
            }
            case '0110': {
                color = colors.status.green;
                icon = AuxBottom23;
                description = 'AUX 2 & 3';
                break;
            }
            case '0101': {
                color = colors.status.green;
                icon = AuxBottom24;
                description = 'AUX 2 & 4';
                break;
            }
            case '0011': {
                color = colors.status.green;
                icon = AuxBottom34;
                description = 'AUX 3 & 4';
                break;
            }
            case '1110': {
                color = colors.status.green;
                icon = AuxBottom123;
                description = 'AUX 1, 2, 3';
                break;
            }
            case '1101': {
                color = colors.status.green;
                icon = AuxBottom124;
                description = 'AUX 1, 2, 4';
                break;
            }
            case '1011': {
                color = colors.status.green;
                icon = AuxBottom134;
                description = 'AUX 1, 3, 4';
                break;
            }
            case '0111': {
                color = colors.status.green;
                icon = AuxBottom234;
                description = 'AUX 2, 3, 4';
                break;
            }
            case '1111': {
                color = colors.status.green;
                icon = AuxBottom1234;
                description = 'AUX 1, 2, 3, 4';
                break;
            }
            default:
        }
        status = {
            color,
            description,
            icon,
        };
        return status;
    } else {
        return null;
    }
};

export const getStatus = (
    vehicle: ?VehicleType,
    eventTypeId: number,
    eventTypes: any,
    sidemenu: ?boolean = false,
    isKey: ?boolean
) => {
    let status = null;

    const additionalData = vehicle.get('additionalData')

    if (eventTypeId) {
        const eventType = eventTypes.get(`${eventTypeId}`);
        if (eventType) {
            let description = eventType.get('title');
            let color;
            let icon;

            if (eventType.get('description').startsWith('TEMP')) {
                status = {
                    icon: sidemenu ? TempAlert : TempAlertWhite,
                    color: colors.status.red,
                    description,
                    eventType: eventType.get('description'),
                };
                return status;
            }

            switch (eventType.get('description')) {
                case 'STOLEN':
                    //color = colors.status.red;
                    icon = sidemenu ? TheftEvent : TheftEvent;
                    break;
                case 'END_ALARM_WEB':
                case 'END_ALARM_MAN':
                    color = colors.status.green;
                    icon = sidemenu ? ThieftEventTransition : ThieftEventTransition;
                    break;
                case 'AUX':
                case 'AUX2':
                case 'AUX3':
                case 'AUX4':
                    description = '';
                case 'KEY_ON':
                case 'DRIVER_IDENT':
                case 'ENGINE_ON':
                case 'ENGINE_OFF':
                case 'ECU':
                case 'STATE':
                case 'BUTTON':
                case 'PING':
                    if (additionalData) {
                        let addData = additionalData;
                        if (additionalData.toJS) {
                            addData = additionalData.toJS();
                        }
                        if (addData.icons) {
                            icon = getMapCustomIcons(addData.icons.vehicle.id, addData.icons.vehicle.color).icon;
                        }
                        else {
                            icon = sidemenu ? defaultVeh : defaultVeh;
                        }
                    } else {
                        icon = sidemenu ? defaultVeh : defaultVeh;
                    }

                    color = colors.status.green
                    break;
                case 'KEY_OFF':
                    color = colors.black;
                    icon = Stopped;
                    break;
                case 'MOTOR_OFF':
                    color = colors.status.green;
                    icon = sidemenu ? EngineCut : EngineCut;
                    break;
                case 'DISCONNECT_CELL':
                    color = colors.black;
                    icon = sidemenu ? OutOfNetwork : OutOfNetwork;
                    break;
                case 'IDLE':
                    color = colors.status.orange;
                    icon = sidemenu ? ProlongedIdling : ProlongedIdling;
                    break;
                case 'FAST_ACC':
                    color = colors.status.orange;
                    icon = sidemenu ? FastAccWhite : FastAccWhite;
                    break;
                case 'FAST_DECEL':
                    color = colors.status.orange;
                    icon = sidemenu ? FastDecelWhite : FastDecelWhite;
                    break;
                case 'MAX_SPEED_THRESHOLD':
                    color = colors.status.orange;
                    icon = sidemenu ? MaxSpeed : MaxSpeed;
                    break;
                case 'MIN_SPEED_THRESHOLD':
                    color = colors.status.green;
                    icon = sidemenu ? MinSpeed : MinSpeed;
                    break;
                case 'IMPACT':
                    color = colors.status.red;
                    icon = sidemenu ? Impact : Impact;
                    break;
                case 'CONNECT_CELL': {
                    color = colors.status.green;
                    icon = sidemenu ? ConnectCell : ConnectCell;
                    break;
                }
                case 'VIMS': {
                    color = colors.status.orange;
                    icon = sidemenu ? Weight : Weight;
                    break;
                }
                case 'LOW_POWER': {
                    color = colors.status.green;
                    icon = sidemenu ? LowPower : LowPower;
                    break;
                }
                default:
                    color = colors.status.green;
                    icon = sidemenu ? defaultVeh : defaultVeh;
            }

            status = {
                color,
                description,
                icon,
                eventType: eventType.get('description'),
            };
        }
    }

    return status;
};


const getAuxStatusIcon = (auxStatus, isKey, eventTypeId, eventType) => {
    if (auxStatus != null && (auxStatus['aux1'] ||auxStatus['aux2'] ||auxStatus['aux3'] ||auxStatus['aux4'])) {
        let combination = '';
        let color;
        let icon;
        let highlight = null;

        combination += auxStatus['aux1'] ? 1 : 0;
        combination += auxStatus['aux2'] ? 1 : 0;
        combination += auxStatus['aux3'] ? 1 : 0;
        combination += auxStatus['aux4'] ? 1 : 0;

        switch (combination) {
            case '0000': {
                switch (eventTypeId) {
                    // verify if it is an aux 1 to aux 4
                    case 9:
                    case 17:
                    case 18:
                    case 19:
                        color = colors.status.black;
                        icon = isKey ? Aux0 : Aux0Off;
                        break;
                }
                break;
            }
            case '1000': {
                color = colors.status.green;
                icon = isKey ? Aux1 : Aux1Off;
                break;
            }
            case '0100': {
                color = colors.status.green;
                icon = isKey ? Aux2 : Aux2Off;
                break;
            }
            case '0010': {
                color = colors.status.green;
                icon = isKey ? Aux3 : Aux3Off;
                break;
            }
            case '0001': {
                color = colors.status.green;
                icon = isKey ? Aux4 : Aux4Off;
                break;
            }
            case '1100': {
                color = colors.status.green;
                icon = isKey ? Aux12 : Aux12Off;
                break;
            }
            case '1010': {
                color = colors.status.green;
                icon = isKey ? Aux13 : Aux13Off;
                break;
            }
            case '1001': {
                color = colors.status.green;
                icon = isKey ? Aux14 : Aux14Off;
                break;
            }
            case '0110': {
                color = colors.status.green;
                icon = isKey ? Aux23 : Aux23Off;
                break;
            }
            case '0101': {
                color = colors.status.green;
                icon = isKey ? Aux24 : Aux24Off;
                break;
            }
            case '0011': {
                color = colors.status.green;
                icon = isKey ? Aux34 : Aux34Off;
                break;
            }
            case '1110': {
                color = colors.status.green;
                icon = isKey ? Aux123 : Aux123Off;
                break;
            }
            case '1101': {
                color = colors.status.green;
                icon = isKey ? Aux124 : Aux124Off;
                break;
            }
            case '1011': {
                color = colors.status.green;
                icon = isKey ? Aux134 : Aux134Off;
                break;
            }
            case '0111': {
                color = colors.status.green;
                icon = isKey ? Aux234 : Aux234Off;
                break;
            }
            case '1111': {
                color = colors.status.green;
                icon = isKey ? Aux1234 : Aux1234Off;
                break;
            }
            default:
                break;
        }

        status = {
            color: colors.status.green,
            description,
            icon,
            highlight,
            eventType: eventType.get('description'),
        };
        return status;
    }
}
/**
 *
 * @param {*} vehicle
 * @param {*} eventTypeId
 * @param {*} eventTypes
 * @param {*} sidemenu
 */
export const getMapVehicleStatus = (
    vehicle: ?VehicleType,
    eventTypeId: number,
    eventTypes: any,
    sidemenu: ?boolean = false,
    isKey: ?boolean,
    auxStatus: ?(number[]),
    history: ?boolean = false
) => {
    let status = null;

    const additionalData = vehicle ? vehicle.get('additionalData') : null;

    if (eventTypeId) {
        const eventType = eventTypes.get(`${eventTypeId}`);
        if (eventType) {
            const description = eventType.get('title');
            let color;
            let icon;
            let highlight = null;

            if(false) { //disabled for now
                return getAuxStatusIcon(auxStatus, isKey, eventTypeId, eventType);
            }

            if (eventType.get('description').startsWith('TEMP')) {
                status = {
                    icon: StatusTempAlertWhite,
                    highlight: StatusTempAlertWhiteHighlight,
                    color: colors.status.red,
                    description,
                    eventType: eventType.get('description'),
                };
                return status;
            }

            switch (eventType.get('description')) {
                // 2
                case 'STOLEN':
                    //color = colors.status.red;
                    icon = TheftEvent;
                    highlight = star;
                    break;
                // 3
                case 'END_ALARM_WEB':
                case 'END_ALARM_MAN':
                    color = colors.status.green;
                    icon = ThieftEventTransition;
                    highlight = star;
                    break;
                // 4
                case 'ENGINE_ON':
                case 'KEY_ON':
                case 'DRIVER_IDENT':
                case 'ECU':
                case 'STATE':
                case 'BUTTON':
                case 'PING':
                case 'ENGINE_OFF':
                    if(additionalData && additionalData.toJS().icons !== undefined){
                        color = getMapCustomIcons(additionalData.toJS().icons.vehicle.id,additionalData.toJS().icons.vehicle.color).color;
                        icon = getMapCustomIcons(additionalData.toJS().icons.vehicle.id,additionalData.toJS().icons.vehicle.color).icon;
                    }else{
                         color = colors.status.green;
                         icon = defaultVeh;
                    }
                    highlight = star;
                    break;

                case 'KEY_OFF':
                    color = colors.black;
                    icon = Stopped;
                    highlight = star;
                    break;
                case 'MOTOR_OFF':
                    color = colors.status.green;
                    icon = EngineCut;
                    highlight = star;
                    break;
                // 7
                case 'DISCONNECT_CELL':
                    color = colors.black;
                    icon = OutOfNetwork;
                    highlight = star;
                    break;
                // 8
                case 'IDLE':
                    color = colors.status.orange;
                    icon = ProlongedIdling;
                    highlight = star;
                    break;
                // 9
                case 'FAST_ACC':
                    color = colors.status.orange;
                    icon = StatusFastAccWhite;
                    highlight = star;
                    break;
                case 'SIDES_ACCEL':
                case 'FWD_ACCEL':
                case 'REV_ACCEL':
                case 'REV_DECEL':
                    color = colors.status.orange;
                    icon = StatusFastAccWhite;
                    highlight = star;
                    break;
                // 10
                case 'FAST_DECEL':
                    color = colors.status.orange;
                    icon = StatusFastDecelWhite;
                    highlight = star;
                case 'FWD_DECEL':
                    color = colors.status.orange;
                    icon = StatusFastDecelWhite;
                    highlight = star;
                    break;
                case 'MAX_SPEED_THRESHOLD':
                    color = colors.status.orange;
                    icon = MaxSpeed;
                    highlight = star;
                    break;
                case 'MIN_SPEED_THRESHOLD':
                    color = colors.status.green;
                    icon = MinSpeed;
                    highlight = star;
                    break;
                case 'IMPACT':
                    color = colors.status.red;
                    icon = Impact;
                    highlight = star;
                    break;
                case 'CONNECT_CELL': {
                    color = colors.status.green;
                    icon = ConnectCell;
                    highlight = star;
                    break;
                }
                case 'VIMS': {
                    color = colors.status.orange;
                    icon = Weight;
                    highlight = star;
                    break;
                }
                case 'LOW_POWER': {
                    color = colors.status.green;
                    icon = LowPower;
                    highlight = star;
                    break;
                }

                default:
                    color = colors.status.green;
                    icon = defaultVeh;
                    highlight = star;
            }

            status = {
                color,
                description,
                icon,
                highlight,
                eventType: eventType.get('description'),
            };
        }

        return status;
    }
};

export const getMapCustomIcons = (id, getColor) => {
    let status = {};
    let icon = '';

    switch (id) {
        case 0:
            icon = defaultVehCustom;
            break;
        case 1:
            icon = vehicleIcon;
            break;
        case 2:
            icon = backHoeIcon;
            break;
        case 3:
            icon = conveyorIcon;
            break;
        case 4:
            icon = forkliftIcon;
            break;
        case 5:
            icon = generatorIcon;
            break;
        case 6:
            icon = groveIcon;
            break;
        case 7:
            icon = loaderIcon;
            break;
        case 8:
            icon = minivanIcon;
            break;
        case 9:
            icon = paverIcon;
            break;
        case 10:
            icon = recycleIcon;
            break;
        case 11:
            icon = rollerIcon;
            break;
        case 12:
            icon = semiTrailerIcon;
            break;
        case 13:
            icon = snowPlowIcon;
            break;
        case 14:
            icon = truckTractorIcon;
            break;
        case 15:
            icon = truckTrashIcon;
            break;
        case 16:
            icon = truckIcon;
            break;
        case 17:
            icon = vttIcon;
            break;
        case 18:
            icon = accelerationIcon;
            break;
        case 19:
            icon = alarmeIcon;
            break;
        case 20:
            icon = alarmeEndIcon;
            break;
        case 21:
            icon = backDoorIcon;
            break;
        case 22:
            icon = benneIcon;
            break;
        case 23:
            icon = buttonIcon;
            break;
        case 24:
            icon = decelerationIcon;
            break;
        case 25:
            icon = driverDoorIcon;
            break;
        case 26:
            icon = exceedIcon;
            break;
        case 27:
            icon = exceedEndIcon;
            break;
        case 28:
            icon = fourDoorsIcon;
            break;
        case 29:
            icon = frontPlowIcon;
            break;
        case 30:
            icon = garbageBinIcon;
            break;
        case 31:
            icon = identificationIcon;
            break;
        case 32:
            icon = keyStartIcon;
            break;
        case 33:
            icon = nonAutoriseIcon;
            break;
        case 34:
            icon = passengerDoor;
            break;
        case 35:
            icon = personIcon;
            break;
        case 36:
            icon = ptoIcon;
            break;
        case 37:
            icon = reverseIcon;
            break;
        case 38:
            icon = seatbeltsIcon;
            break;
        case 39:
            icon = sidePlowIcon;
            break;
        case 40:
            icon = beaconIcon;
            break;
        case 41:
            icon = smokeDetectorIcon;
            break;
        case 42:
            icon = startButtonIcon;
            break;
        case 43:
            icon = telephoneIcon;
            break;
        case 44:
            icon = temperatureIcon;
            break;
        case 45:
            icon = personalButtonIcon;
            break;
        case 46:
            icon = professionalButtonIcon;
            break;
        case 47:
            icon = lightingTower;
            break;
        case 48:
            icon = lightingPlatform;
            break;
        case 49:
            icon = excavator;
            break;
        case 50:
            icon = boat;
            break;
        case 51:
            icon = compressor;
            break;
        case 52:
            icon = welder;
            break;
        case 53:
            icon = drill;
            break;
        case 54:
            icon = ripperTeeth;
            break;
        case 55:
            icon = forklifts;
            break;
        case 56:
            icon = toothedBucket;
            break;
        case 57:
            icon = hydraulicJack;
            break;
        default:
            break;
    }

    status = {
        color: getColor,
        highlight: star,
        icon,
    };
    return status;
};

export const sortVehiclesByEventType = (vehicles: Array<VehicleType>) => {
    const n = vehicles.length;
    const arr = Array.from(vehicles);
    for (let i = 0; i < n - 1; i++) {
        for (let j = 0; j < n - i - 1; j++) {
            if (
                arr[j].getIn(['status', 'eventTypeId']) >
                arr[j + 1].getIn(['status', 'eventTypeId'])
            ) {
                const temp = vehicles[j];
                arr[j] = arr[j + 1];
                arr[j + 1] = temp;
            }
        }
    }
    return arr;
};

export const vehicleSameLocation = (vehicles: VehicleListType, prevVehicles) => {
    const modifiedVehicles = vehicles.map((vehicle) => {
        if (prevVehicles) {
            prevVehicles.forEach((prevVeh) => {
                if (prevVeh.get('id') == vehicle.id && prevVeh.getIn(['travelLines'])) {
                    vehicle.travelLines = prevVeh.getIn(['travelLines']);
                    vehicle.targetLocation = {};
                    vehicle.targetLocation.longitudeWgs84 = prevVeh.getIn([
                        'targetLocation',
                        'longitudeWgs84',
                    ]);
                    vehicle.targetLocation.latitudeWgs84 = prevVeh.getIn([
                        'targetLocation',
                        'latitudeWgs84',
                    ]);

                    vehicle.latestRealTime.longitudeWgs84 = prevVeh.getIn([
                        'latestRealTime',
                        'longitudeWgs84',
                    ]);
                    vehicle.latestRealTime.latitudeWgs84 = prevVeh.getIn([
                        'latestRealTime',
                        'latitudeWgs84',
                    ]);
                }
            });
        }
        if (!vehicle.latestRealTime) {
            return vehicle;
        }

        const sameLoactionIndex = vehicles.findIndex((fvehicle) => {
            return (
                fvehicle.latestRealTime &&
                fvehicle.id !== vehicle.id &&
                fvehicle.latestRealTime.latitudeWgs84 === vehicle.latestRealTime.latitudeWgs84 &&
                fvehicle.latestRealTime.longitudeWgs84 === vehicle.latestRealTime.longitudeWgs84
            );
        });

        if (sameLoactionIndex >= 0) {
            vehicle.duplicateLocation = true;
        }

        return vehicle;
    });
    return modifiedVehicles;
};

export const parseJWT = (token: UserType) => {
    if (!token || token === 'undefined') return null;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};

export const formatDate = (date) =>
    `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

export const formatAddressFromType = (address: string | object) => {
    if (typeof address === 'object') {
        if(address.get('city').toLowerCase() === 'churchill falls'){
            return [
                address.get('province'),
                address.get('country'),
            ].join(' ');
        }else{
            return [
                address.get('streetAddress'),
                address.get('city'),
                address.get('province'),
                address.get('country'),
            ].join(' ');
        }
    } else if (typeof address === 'string') {
        return address;
    } else {
        return '';
    }
};

export const setSpreaderState = (state) => {
    const stateInfo = [
        'components.MapPopup.State.Stop',
        'components.MapPopup.State.Start',
        'components.MapPopup.State.Config',
        'components.MapPopup.State.Unloading',
    ];

    let setState;

    switch (state) {
        case 0: // Stop
            setState = stateInfo[0];
            break;
        case 1: // Start
            setState = stateInfo[1];
            break;
        case 2: // Config
            setState = stateInfo[2];
            break;
        case 3: // Unload
            setState = stateInfo[3];
            break;
        default:
            setState = 'NA';
            break;
    }
    return setState;
};

export const setSpreaderMode = (mode) => {
    const modeInfo = [
        'components.MapPopup.Mode.Idle',
        'components.MapPopup.Mode.SpreadingOrSpraying',
        'components.MapPopup.Mode.Unload',
        'components.MapPopup.Mode.SpreadingAndSpraying',
        'components.MapPopup.Mode.Spreading',
        'components.MapPopup.Mode.Spraying',
    ];

    let setMode;
    switch (mode) {
        case 0: // idle
            setMode = modeInfo[0];
            break;
        case 1: // spreading or spraying
            setMode = modeInfo[1];
            break;
        case 2: // unload hopper
            setMode = modeInfo[2];
            break;
        case 3: // spreading and spraying
            setMode = modeInfo[3];
            break;
        case 4: // spreading
            setMode = modeInfo[4];
            break;
        case 5: // spraying
            setMode = modeInfo[5];
            break;
        default:
            setMode = 'NA';
            break;
    }

    return setMode;
};

export const getMaterial = (material) => {
    const materialInfo = [
        'components.MapPopup.material.salt',
        'components.MapPopup.material.sand',
        'components.MapPopup.material.custom',
    ];

    switch (material) {
        case 0:
            return materialInfo[0];
        case 1:
            return materialInfo[1];
        case 2:
            return materialInfo[2];
        default:
            return '';
    }
};

export const getCustomIcon = (vehicle) => {
   if(vehicle.get('customIcon')) {
       return JSON.parse(JSON.stringify(vehicle.get('customIcon')));
   }
   else {
       return '';
   }
};

export class CircularQueue {
    elements = [];
    length = 0;
    front = 0;
    back = -1;
    size = 10;
    constructor(size) {
        this.size = size;
    }
    isEmpty() {
        return this.length === 0;
    }

    push(element) {
        return this.enqueue(element);
    }
    enqueue(element) {
        if (this.length >= this.size) {
            this.dequeue();
        }
        this.length++;
        this.back++;
        this.elements[this.back % this.size] = element;
    }

    pop() {
        return this.dequeue();
    }
    dequeue() {
        if (this.isEmpty()) {
            return null;
        }
        const value = this.peek();
        this.elements[this.front % this.size] = null;
        this.front++;
        this.length--;
        return value;
    }
    peek() {
        if (this.isEmpty()) {
            return null;
        }
        return this.elements[this.front % this.size];
    }

    peekLast() {
        if (this.isEmpty()) {
            return null;
        }
        return this.elements[this.back % this.size];
    }
    clear() {
        this.elements = [];
        this.length = 0;
        this.front = 0;
        this.back = -1;
    }
    contents() {
        if (this.isEmpty()) {
            return List();
        }
        let contents = [];
        let i;
        for (i = 0; i < this.length; i++) {
            const element = this.elements[(this.front + i) % this.size];
            contents.push(element);
        }
        return List(contents);
    }

    size() {
        return this.size;
    }
}

export class TravelLines extends CircularQueue {

    totalDistance = 0;

    distance() {
        return this.totalDistance;
    }

    recalculateLength() {
        if (this.contents()) {
            this.totalDistance = this.contents().reduce((total, line) => {
                return total + line.length;
            }, 0);
        }
    }

    enqueue(element) {
        super.enqueue(element);
        this.recalculateLength();
    }

    dequeue() {
        const element = super.dequeue();
        this.recalculateLength();
        return element;
    }
}

export const loadCustomIconHelper = (img) => {
    // console.log(vehicleIcon);
    // console.log(' custom:' + img);

    if (vehicleIcon == img) {
    }

    return <VehicleIcon />;
};

export const isIconeCustomized = (data) => {
    if(
        data.get('vehicle') &&
        data.getIn(['vehicle', 'id']) >= 0 &&
        data.getIn(['vehicle', 'color'])
    ){

        return true
    }
    return false

};

