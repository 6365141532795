import React from 'react';
import { injectIntl } from 'react-intl';
import Modal from '@mui/material/Modal';
import { Grid, Box, Chip } from '@mui/material';
import './index.scss';
import { connect } from 'react-redux';
import { closeMoveReportModal } from 'services/Report/thunks';
import { selectMoveInfo } from 'services/Report/selectors';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { selectLocale } from "../../../services/Language/selectors";
import MoveReportMap from '../../../components/MoveReportMap';
import DriverChip from '../../Chip';
import { ArrowSvg } from '../../Icons';
import { StartPositionIcon, EndPositionIcon } from '../../Icons';
function MoveReportModal(props) {

    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        props.closeMoveReportModal();
        setOpen(false)
    };
    return (<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box className={'Box'}
        >
            <Grid container className={'container'} mb={'10px'} mt={-5} spacing={2}>
                <Grid item >
                    <div className={'title'}>{props.moveInfo.vehiculeNumb}</div>
                </Grid>
                <Grid item >
                    <Chip label={props.moveInfo.distance} sx={{ backgroundColor: '#63AC37', color: 'white', fontSize: '14px', fontWeight: '800', }} />
                </Grid>
                <Grid item >
                    {(props.moveInfo.driverName) ? (
                        <Box>
                            <DriverChip
                                type="driver"
                                color={props.moveInfo.driverChipColor}
                            >
                                <i className="far fa-id-badge" />
                                &nbsp;
                                {props.moveInfo.driverName}
                            </DriverChip>
                        </Box>
                    ) : (
                        <empty />
                    )}
                </Grid>
                <Grid item >
                    <Box className={'address-box'}>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={'address-title'}>{props.intl.formatMessage({
                                    id: 'Components.Reports.Move.AddressCard.Start',
                                })}</div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={'address-text'}>
                                    <StartPositionIcon></StartPositionIcon>
                                    {props.moveInfo.startAddress}
                                </div>
                            </Grid>
                            <Grid >
                                <div className='time-text'>{`${props.moveInfo.periodDateFrom} à ${props.moveInfo.periodTimeFrom}`}</div>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item >
                    <ArrowSvg></ArrowSvg>
                </Grid>
                <Grid item >
                    <Box className={'address-box'}>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={'address-title'}>{props.intl.formatMessage({
                                    id: 'Components.Reports.Move.AddressCard.End',
                                })}</div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={'address-text'}>
                                    <EndPositionIcon></EndPositionIcon>
                                    {props.moveInfo.stopAddress}
                                </div>
                            </Grid>
                            <Grid >
                                <div className='time-text'>{`${props.moveInfo.periodDateTo} à ${props.moveInfo.periodTimeTo}`}</div>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <MoveReportMap />
        </Box>
    </Modal >)

}

const mapStateToProps = createStructuredSelector({

    locale: selectLocale(),
    moveInfo: selectMoveInfo(),

});
const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            closeMoveReportModal,
        },
        dispatch
    );
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(MoveReportModal)))